<template>
  <div>
    <div class="text-h5 text-sm-h5 text-md-h4 text-lg-h4 ma-3 text-xl-h4 text-center">
      Trazabilidad
    </div>

    <v-btn
      color="primary"
      class="ml-4 mr-4 mt-4 green"
      @click="nuevo"
      style="width:100px"
      >
      Nuevo
    </v-btn>

    <v-data-table
      :headers="headers"
      :items="dataRows"
      :options.sync="options"
      :footer-props="{itemsPerPageOptions: [15, 30, 50, 100]}"
      :server-items-length="totalDataRows"
      :loading="LoadingData"
      style="overflow: scroll"
      mobile-breakpoint="0"
      class="elevation-1"
      dense
      @click:row="seleccion"
      item-key="CtrlId"
      >

        <!-- <template v-slot:item.TrazTmp="{ item }">
          <v-text-field dense reverse v-model="item.TrazTmp" type="text" class="pt-0 pb-1 mb-n7" solo flat ></v-text-field>
        </template> -->

    </v-data-table>
  </div>
</template>

<script>
  // import axios from "axios";
  import { mapState, mapMutations, mapActions } from "vuex";
  export default {
    computed:{
        ...mapState(['empId', 'perId', 'urlRaiz']),
        ...mapState('Traz', ['CurrentEntity', 'LoadingData', 'EntitiesList', 'EntitiesCount'])
    },
    data () {
      return {
        totalDataRows: 0,
        dataRows: [],
        loading: true,
        options: {itemsPerPage: 15},
        headers: [
          { text: 'Fecha', width: 150, sortable: false, value: 'TrazTmpStr' },
          { text: 'Tipo', width: 300, sortable: false, value: '_ArtCatStr' },
          { text: 'Realizado por', width: 250, class: 'col_para', sortable: false, value: '_Per.PerNom' },
        ],
        // headers: [
        //   { text: 'Fecha', width: 150, sortable: false, value: 'CtrlFechaStr' },
        //   { text: 'Tipo', width:300, sortable: false, value: 'CtrlCatNom' },
        //   { text: 'Realizado por', width: 250, class: 'col_para', sortable: false, value: 'PerNom' },
        // ],
      }
    },
    watch: {
      options: {
        handler () {
          // this.getDataFromApi()
          const paramsObj = {NumRegsPag: this.options.itemsPerPage, NumPag: this.options.page, SortBy: this.options.sortBy, SortDesc: this.options.sortDesc};
          this.GetEntities(paramsObj)
            .then(data=>{
              this.dataRows = data.EntitiesFront;
              this.totalDataRows = data.totalItems;

              // this.dataRows = this.EntitiesList;
              // this.totalDataRows = this.EntitiesCount;
            }
              
              // data => {
              // this.dataRows = data.items
              // this.totalDataRows =  data.total
            // }
            )
        },
        deep: false,
      },
    },
    mounted () {
      // this.getDataFromApi()
      // this.GetEntities(this.options.itemsPerPage, this.options.page, this.options.sortBy, this.options.sortDesc)
      //   .then(data => {
      //     this.dataRows = data.items
      //     this.totalDataRows = data.total
      //   })
    },
    methods: {
      ...mapMutations('Traz', ['CurrentEntitySet', 'LoadingDataSet']),
      ...mapActions('Traz', ['GetEntities', 'SelectEntity', 'NewEntity']),
      seleccion(itm){ 
        // this.CurrentEntitySet(itm);
        this.SelectEntity(itm).then(() => {
          this.$router.push('/control-trazabilidad-edicion')
       });
      },
      nuevo(){
        this.NewEntity();
      },
      // getDataFromApi () {
      //   this.loading = true
      //   return new Promise((resolve) => {
      //     const { sortBy, sortDesc } = this.options

      //     var NumRegsPag = this.options.itemsPerPage;
      //     var NumPag = this.options.page;
      
      //     var controllerParameters = {
      //               Action: "GET_DATA_LIST_PAGINATED",   
      //               NumRegsPag: NumRegsPag,
      //               NumPag: NumPag,
      //               EmpId: this.empId,
      //               DirId: this.$store.state.dir.DirId
      //           } ;       

      //     var AuthToken = localStorage.getItem('token');
      //     axios({ method: "POST", "url": this.urlRaiz + "/api/traz", "data": JSON.stringify(controllerParameters), "headers": {"content-type": "application/json", "Authorization": AuthToken } }).then(result => {
      //       let items = result.data.EntsList;
      //       const total = result.data.EntsTotCount;

      //     if (sortBy.length === 1 && sortDesc.length === 1) {
      //       items = items.sort((a, b) => {
      //         const sortA = a[sortBy[0]]
      //         const sortB = b[sortBy[0]]

      //         if (sortDesc[0]) {
      //           if (sortA < sortB) return 1
      //           if (sortA > sortB) return -1
      //           return 0
      //         } else {
      //           if (sortA < sortB) return -1
      //           if (sortA > sortB) return 1
      //           return 0
      //         }
      //       })
      //     }

      //     setTimeout(() => {
      //       this.loading = false
      //       resolve({
      //         items,
      //         total,
      //       })
      //     }, 1000)
      //     });
      //   })
      // },
    },
  }
</script>
<style>
  .col_para {
    min-width: 250px;
  }
</style>